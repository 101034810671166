import React, { useState } from "react";
import * as dayjs from "dayjs";
import classnames from "classnames";
import { useFormik } from "formik";
import { Toastr } from "@bigbinary/neetoui";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { getProfileUrl } from "@/lib/utils";
import { ORDINALIZED_DATE_FORMAT, MAX_STRING_LENGTH } from "@/lib/constants";
import { showToastrError } from "@/components/commons";
import { createSubscription } from "@/apis/subscriptions";

import { Input, Button } from "@/components/commons/SettingsFormUI";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";
import LinkRollCard from "./LinkRollCard";
import ImageCard from "./ImageCard";
import FriendCard from "./FriendCard";
import FooterBanner from "./FooterBanner";
import TakeHeader from "./TakeHeader";

const TakeDetails = ({
  take,
  user,
  subscriptions,
  attachments,
  linkRolls,
  mobilePreview,
}) => {
  dayjs.extend(advancedFormat);
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    onSubmit: () => createSubscriptionResponse(),
  });

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: formik.values.email, user_id: user.id },
      });
      Toastr.success(data.message);
      formik.resetForm();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="pb-take--header flex flex-col items-center space-y-2 w-full dark:bg-[#333333] dark:text-white rounded-t-xl">
        <span className="text-center">{take.subject}</span>
        <span>
          {dayjs(take.sent_at || take.created_at).format(
            ORDINALIZED_DATE_FORMAT,
          )}
        </span>
      </div>

      <div
        className={classnames(
          "flex flex-col items-center max-w-[720px] w-full space-y-12 divide-y divide-[#e5e0d9] py-12 px-6 dark:divide-white",
          {
            "md:px-12": !mobilePreview,
          },
        )}
      >
        <div className="flex flex-col items-center space-y-12 w-full">
          <div className="flex flex-col w-full items-center space-y-4">
            <TakeHeader take={take} user={user} />

            <h3 className="pb-take--user-name font-soehne-kraftig dark:text-white text-center">
              {user.display_name}
            </h3>
          </div>

          {take.intro?.length > 0 && (
            <p
              className="pb-take--text w-full dark:text-white"
              dangerouslySetInnerHTML={{
                __html: take.intro,
              }}
            />
          )}
        </div>

        {attachments.length > 0 && (
          <div className="flex flex-col items-center pt-12 space-y-12 w-full">
            {attachments.map((attachment, index) => {
              return attachment.link ? (
                <a href={attachment.link} target="_blank" className="w-full">
                  <ImageCard image={attachment} key={index} />
                </a>
              ) : (
                <ImageCard image={attachment} key={index} />
              );
            })}
          </div>
        )}

        {take.link_rolls_enabled && (
          <div className="flex flex-col items-center pt-12 space-y-12 w-full">
            <h3 className="pb-take--user-name text-center font-soehne-kraftig dark:text-white">
              {take.link_roll_title}
            </h3>

            {take.link_roll_intro?.length > 0 && (
              <p
                className="pb-take--text text-center dark:text-white"
                dangerouslySetInnerHTML={{
                  __html: take.link_roll_intro?.split("\n")?.join("<br/>"),
                }}
              />
            )}

            <div className="flex flex-col items-center space-y-8 w-full">
              {linkRolls.map((linkRoll, index) => {
                return <LinkRollCard linkRoll={linkRoll} key={index} />;
              })}
            </div>
          </div>
        )}

        <div className="flex flex-col items-center pt-12 space-y-12 w-full">
          {take.outro?.length > 0 && (
            <p
              className="pb-take--text w-full dark:text-white"
              dangerouslySetInnerHTML={{
                __html: take.outro,
              }}
            />
          )}

          <div className="flex flex-col items-center space-y-6 w-full">
            {user.personal_url && (
              <>
                <p className="pb-take--text text-center dark:text-white">
                  Want more from {user.name}?
                </p>

                <a
                  href={user.personal_url}
                  className="py-1 px-4 bg-black text-white rounded-full text-[15px] leading-[24px] text-center break-all dark:text-black dark:bg-white"
                >
                  {user.personal_url}
                </a>
              </>
            )}

            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 gap-x-3 max-w-[450px] w-full">
              <Input
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                placeholder="Enter email address"
                maxLength={MAX_STRING_LENGTH}
              />

              <Button
                onClick={formik.handleSubmit}
                type="submit"
                label="Subscribe"
                loading={btnLoader}
                loginBtn
                className="shrink-0"
              />
            </div>

            <a
              href={getProfileUrl(user.url_slug)}
              className="pb-take--text underline dark:text-white"
            >
              View previous Takes
            </a>
          </div>
        </div>

        {subscriptions?.length > 0 && (
          <div className="flex flex-col items-center pt-12 space-y-12 md:space-y-6 w-full">
            <h3 className="pb-take--user-name font-soehne-kraftig !text-[25px] dark:text-white">
              Follow my friends!
            </h3>

            {subscriptions.length === 1 && (
              <div className={`grid grid-cols-1 gap-4 w-full`}>
                {subscriptions.map((subscription) => (
                  <FriendCard
                    key={subscription.id}
                    subscription={subscription}
                  />
                ))}
              </div>
            )}

            {(subscriptions.length === 2 || subscriptions.length === 4) && (
              <div className={`grid grid-cols-2 gap-4 w-full`}>
                {subscriptions.map((subscription) => (
                  <FriendCard
                    key={subscription.id}
                    subscription={subscription}
                  />
                ))}
              </div>
            )}

            {subscriptions.length === 3 && (
              <div className="flex flex-col w-full space-y-4">
                <div className={`grid grid-cols-2 gap-4 w-full`}>
                  {subscriptions.slice(0, 2).map((subscription) => (
                    <FriendCard
                      key={subscription.id}
                      subscription={subscription}
                    />
                  ))}
                </div>

                <div className={`grid grid-cols-1 gap-4 w-full`}>
                  {subscriptions.slice(1, 2).map((subscription) => (
                    <FriendCard
                      key={subscription.id}
                      subscription={subscription}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center max-w-[720px] w-full pb-12 px-6 md:px-12">
        <FooterBanner />
        <div className="bg-primary py-3 px-4 rounded-b-lg">
          <img
            src={PencilBoothLogo}
            className="w-full h-4"
            alt="PencilBooth Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default TakeDetails;
