import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useFormik } from "formik";
import { Toastr, Spinner, Modal, Select } from "@bigbinary/neetoui";
import { Copy } from "@bigbinary/neeto-icons";
import { useParams } from "react-router-dom";

import { AUTH_TOKEN } from "@/lib/constants";
import { dropDownListGenerator } from "@/lib/utils";
import { useAuthContext } from "@/lib/useAuthContext";
import { getUser, updateUser, destroyUser } from "@/apis/super_admin/users";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { INITIAL_VALUE } from "./constants";
import {
  Input,
  Textarea,
  Switch,
  Button,
} from "@/components/commons/SettingsFormUI";
import { Button as ModalButton } from "@/components/commons/AppFormUI";

const UserEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, setUser } = useAuthContext();
  const [listLoader, setListLoader] = useState(true);
  const [userData, setUserData] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData?.id ? userData : INITIAL_VALUE,
    onSubmit: () => updateUserResponse(),
  });

  useEffect(() => {
    if (id) {
      getUserResponse();
    }
  }, [id]);

  useEffect(() => {
    if (emailCopied) {
      Toastr.success("Credentials copied to clipboard!");
      setEmailCopied(false);
    }
  }, [emailCopied]);

  const getUserResponse = async () => {
    try {
      setListLoader(true);
      const { data } = await getUser(id);
      setUserData(data.user);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(id, {
        user: {
          vip: formik.values.vip,
          featured: formik.values.featured,
          under_review: formik.values.under_review,
        },
      });
      Toastr.success(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const destroyUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyUser(userData.id);
      setAlertOpen(false);
      Toastr.success(data.message);
      localStorage.removeItem(AUTH_TOKEN);
      setUser();
      navigate("/", { replace: true });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (listLoader) {
    return (
      <div className="flex h-screen justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full">
        <h2 className="font-soehne-kraftig pb-6 text-[24px] font-medium leading-snug border-b border-gray-300 lg:text-[32px]">
          User Details
        </h2>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            NAME
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="name"
              name="name"
              value={formik.values.name}
              className="cursor-not-allowed"
              disabled
            />

            <span className="text-sm leading-5 text-lightBlack opacity-60">
              This is the Name of Organisation.
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Email
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="name"
              name="name"
              value={formik.values.email}
              className="cursor-not-allowed"
              disabled
            />

            <span className="text-sm leading-5 text-lightBlack opacity-60">
              This is the email of Organisation.
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            DISPLAY NAME
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="display_name"
              name="display_name"
              value={formik.values.display_name}
              className="cursor-not-allowed"
              disabled
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              This is the name of your PencilBooth that your subscribers will
              see i.e ‘Thank you for reading [Display Name]’s PencilBooth!
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            PENCILBOOTH URL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="url_slug"
              name="url_slug"
              value={formik.values.url_slug}
              className="cursor-not-allowed"
              disabled
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              {`This is the URL (${window.location.host}/${formik.values.url_slug}) where people can subscribe to your PencilBooth.`}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            PERSONAL URL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="personal_url"
              name="personal_url"
              placeholder="Please enter full URL, including https://"
              className="cursor-not-allowed"
              disabled
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Enter a link to an external site where people can find out more
              about you and your work.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            VIP
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.vip}
              onChange={() => formik.setFieldValue("vip", !formik.values.vip)}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Activate this if the Organisation is a VIP and will not be curbed
              by the constraints of capital. They will not be asked to input
              their Credit Card details. However, if they are already
              subscribed, this does not change that.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Profile under Review
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.under_review}
              onChange={() => formik.setFieldValue("under_review", !formik.values.under_review)}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Toggle off the switch when account review is done.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Featured
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.featured}
              onChange={() =>
                formik.setFieldValue("featured", !formik.values.featured)
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Activate this if this booth needs to be featured in featured booth
              page.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Subscription
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Stripe Customer and Subscription IDs. Use these within Stripe to
              search for any associated Customers.
            </span>

            {userData.stripe_customer_id && (
              <div className="flex items-center">
                <span className="mr-2">{userData.stripe_customer_id}</span>
                <CopyToClipboard
                  text={userData.stripe_customer_id}
                  onCopy={() => setEmailCopied(true)}
                >
                  <Copy size={16} className="cursor-pointer" />
                </CopyToClipboard>
              </div>
            )}

            {userData.stripe_subscriber_id && (
              <div className="flex items-center">
                <span className="mr-2">{userData.stripe_subscriber_id}</span>
                <CopyToClipboard
                  text={userData.stripe_subscriber_id}
                  onCopy={() => setEmailCopied(true)}
                >
                  <Copy size={16} className="cursor-pointer" />
                </CopyToClipboard>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end py-4">
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Update"
            loading={btnLoader}
          />
        </div>
        <div className="mt-12 space-y-5 rounded-md bg-white px-6 py-5">
          <div className="flex flex-col w-full gap-y-3">
            <h4 className="font-soehne-kraftig text-lg font-medium text-lightBlack">
              Close your account
            </h4>
            <p className="font-soehne-mono text-sm text-lightBlack">
              By closing your PencilBooth account, you will not be able to
              access your current subscriber list, existing takes and any
              associated assets, all data is removed from our system and cannot
              be recovered. We'd suggest archiving your subsciber list as a
              start before closing your PencilBooth account.
            </p>
          </div>
          <Button
            onClick={() => setAlertOpen(true)}
            label="Close account"
            style="danger"
          />
        </div>
      </AnimatedPage>

      <Modal
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Close your account</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to close your account?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <ModalButton
            label="Yes, Proceed"
            type="submit"
            style="danger"
            onClick={() => destroyUserResponse()}
            loading={btnLoader}
          />

          <ModalButton
            label="Cancel"
            onClick={() => setAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserEdit;
