import React, { useState } from "react";
import * as dayjs from "dayjs";
import * as browserImageSize from "browser-image-size";
import advancedFormat from "dayjs/plugin/advancedFormat";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import {
  MobileIcon,
  EyeIcon,
  CloseEyeIcon,
} from "@/components/commons/PBIcons";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { createTake } from "@/apis/takes";
import { createAttachment, reorderAttachment } from "@/apis/take_attachments";
import { showToastrError } from "@/components/commons";

import TakeImageUploader from "./TakeImageUploader";
import TakeHeader from "./TakeHeader";
import TakeImage from "./TakeImage";
import LinkRollForm from "./LinkRollForm";
import SubscriberDropdown from "./SubscriberDropdown";
import TakeDetails from "../../../Detail/TakeDetails";
import {
  RICH_TEXT_EDITOR_INLINE_MODE,
  RICH_TEXT_EDITOR_TOOLBAR,
} from "./../../constants";

const TakeComposer = ({ formik, user, handleSubmitWithStatus, takeDetail }) => {
  dayjs.extend(advancedFormat);
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState(false);
  const [mobilePreview, setMobilePreview] = useState(false);

  const uploadInProgress = () => {
    return formik.values.attachments_attributes.some(
      (att) => att.imageLoader === true,
    );
  };

  const handleChange = (acceptedFiles) => {
    const files = Array.from(acceptedFiles);
    let imageList = [];

    files.map((file) => {
      let reader = new FileReader();
      const newUuid = uuid();

      browserImageSize(file).then((size) => {
        reader.onload = (e) => {
          let image = {
            file_data: e.target.result,
            file_name: file.name,
            imageLoader: true,
            tempId: newUuid,
            height: size.height,
            width: size.width,
          };
          imageList.push(image);

          if (files.length === imageList.length) {
            formik.setFieldValue("attachments_attributes", [
              ...formik.values.attachments_attributes,
              ...imageList,
            ]);
          }
        };
        reader.readAsDataURL(file);
      });
    });
  };

  const createTakeResponse = async () => {
    try {
      const { data } = await createTake({ take: formik.values });
      formik.setFieldValue("id", data.id);
      return data.id;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createAttachmentWithTake = async (attachment) => {
    createTakeResponse().then(async (takeId) => {
      createAttachmentResponse(attachment, takeId, true);
    });
  };

  const createAttachmentResponse = async (
    attachment,
    takeId,
    navigateToEdit,
  ) => {
    try {
      const { data } = await createAttachment(
        takeId,
        { attachment: attachment },
        { onUploadProgress: () => null },
      );
      const updatedAttachments = formik.values.attachments_attributes.map(
        (att) => {
          if (att.tempId === attachment.tempId) {
            return {
              ...data.attachment,
              imageLoader: false,
            };
          } else {
            return { ...att, in_bottom_position: false };
          }
        },
      );
      formik.setFieldValue("attachments_attributes", updatedAttachments); // ReLoad array of attachments
      if (navigateToEdit)
        navigate(`/takes/${takeId}/edit`, {
          state: {
            pageLoad: false,
          },
        });
    } catch (error) {
      const updatedAttachments = formik.values.attachments_attributes.filter(
        (att) => att.tempId !== attachment.tempId,
      );

      formik.setFieldValue("attachments_attributes", updatedAttachments);
      showToastrError(error.response.data.errors);
    }
  };

  const reorderAttachmentResponse = async (attachmentId, reorderType) => {
    try {
      const { data } = await reorderAttachment(
        formik.values.id,
        attachmentId,
        reorderType,
      );
      formik.setFieldValue("attachments_attributes", data.attachments);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  return (
    <div
      className={classnames("pb-settings--chrome-window relative", {
        "chrome-mobile-window": mobilePreview,
      })}
    >
      <div className="pb-settings--chrome-window--header">
        <div className="pb-settings--chrome-window--control">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className="flex space-x-2 items-center">
          <div
            className="cursor-pointer hidden sm:block"
            onClick={() => setMobilePreview(!mobilePreview)}
          >
            <MobileIcon />
          </div>

          <div
            className="cursor-pointer"
            onClick={() => setShowPreview(!showPreview)}
          >
            {showPreview ? <CloseEyeIcon /> : <EyeIcon />}
          </div>
        </div>
      </div>

      <div>
        <div className="divide-y divide-[#E8E8E8] border-b border-[#E8E8E8] bg-[#fafafa]">
          <div
            className={classnames(
              "grid grid-cols-1 divide-y divide-[#E8E8E8]",
              {
                "lg:divide-y-0 lg:divide-x lg:grid-cols-2": !mobilePreview,
              },
            )}
          >
            <div className="flex w-full px-6 py-[15px] text-base">
              <span className="mr-3 shrink-0">To:</span>
              <span>All Subscribers ({user.subscriberSize})</span>
            </div>
            <div className="flex w-full px-6 py-[15px] text-base">
              <span className="mr-3 shrink-0">From:</span>
              <span>{user.name}</span>
            </div>
          </div>
          <div className="flex w-full px-6 py-[15px] text-base">
            <span className="mr-3 shrink-0">Subject:</span>

            <div className="w-full">
              <input
                value={formik.values.subject}
                onChange={(e) => {
                  formik.setFieldValue("subject", e.target.value);
                }}
                onBlur={() => handleSubmitWithStatus()}
                className="pb-take-caption-input grow"
                placeholder="Write a subject for your take"
              />

              {formik.errors.subject && (
                <div className="text-red-500 text-xs mt-2">
                  {formik.errors.subject}
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full px-6 py-[15px] text-base">
            <span className="mr-3 shrink-0">Preview:</span>
            <input
              value={formik.values.subject_preview}
              onChange={(e) =>
                formik.setFieldValue("subject_preview", e.target.value)
              }
              onBlur={() => handleSubmitWithStatus()}
              className="pb-take-caption-input grow"
              placeholder="Appears after the subject line in an inbox"
            />
          </div>
        </div>

        {showPreview ? (
          <div className="flex flex-col items-center w-full px-3 py-6">
            <TakeDetails
              take={formik.values}
              user={user}
              attachments={formik.values.attachments_attributes}
              linkRolls={formik.values.link_rolls_attributes}
              subscriptions={user.follower_suggestions}
              mobilePreview
            />
          </div>
        ) : (
          <div className="flex flex-col items-center w-full px-3 py-6">
            <div className="pb-take--header flex flex-col items-center space-y-2 w-full">
              <span className="text-center">{formik.values.subject}</span>
              <span>
                {dayjs(
                  formik.values.sent_at || formik.values.created_at,
                ).format(ORDINALIZED_DATE_FORMAT)}
              </span>
            </div>

            <div className="flex flex-col items-center w-full space-y-8 py-8">
              <TakeHeader
                formik={formik}
                takeDetail={takeDetail}
                handleSubmitWithStatus={handleSubmitWithStatus}
              />

              <div className="flex flex-col items-center space-y-8 w-full px-4 sm:px-8 md:px-12">
                <RichTextEditorComponent
                  id="intro-editor"
                  inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
                  toolbarSettings={RICH_TEXT_EDITOR_TOOLBAR}
                  onBlur={() => handleSubmitWithStatus()}
                  change={(e) => formik.setFieldValue("intro", e.value)}
                  value={formik.values.intro}
                  placeholder="Add your intro... (optional)"
                >
                  <Inject
                    services={[Image, Link, QuickToolbar, HtmlEditor, Toolbar]}
                  />
                </RichTextEditorComponent>

                {formik.values.attachments_attributes.map(
                  (attachment, index) => {
                    return (
                      <TakeImage
                        key={index}
                        attachment={attachment}
                        formik={formik}
                        reorderAttachmentResponse={reorderAttachmentResponse}
                        createAttachmentWithTake={createAttachmentWithTake}
                        createAttachmentResponse={createAttachmentResponse}
                      />
                    );
                  },
                )}

                <TakeImageUploader
                  handleChange={handleChange}
                  uploadInProgress={uploadInProgress}
                />

                <LinkRollForm
                  formik={formik}
                  handleSubmitWithStatus={handleSubmitWithStatus}
                />

                <RichTextEditorComponent
                  id="outro-editor"
                  inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
                  toolbarSettings={RICH_TEXT_EDITOR_TOOLBAR}
                  blur={() => handleSubmitWithStatus()}
                  change={(e) => formik.setFieldValue("outro", e.value)}
                  value={formik.values.outro}
                  placeholder="Sign off your post here (optional)"
                >
                  <Inject
                    services={[Image, Link, QuickToolbar, HtmlEditor, Toolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TakeComposer;
