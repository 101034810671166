import React from "react";
import { RouterProvider } from "react-router-dom";
import { Provider, ErrorBoundary } from "@rollbar/react";

import { registerLicense } from "@syncfusion/ej2-base";

import { router } from "@/components/Main";

function App({ rollbar_access_token, environment, syncfusion_license_key }) {
  // Registering Syncfusion license key
  registerLicense(syncfusion_license_key);
  return (
    <Provider
      config={{ accessToken: rollbar_access_token, environment: environment }}
    >
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
