export const MAX_STRING_LENGTH = 250;
export const MAX_SLUG_LENGTH = 20;
export const OTP_LENGTH = 6;
export const AUTH_TOKEN = "PBToken";
export const TAKE_IMAGES_COUNT = 4;
export const DEFAULT_PAGE_SIZE = 10;
export const ORDINALIZED_DATE_FORMAT = "MMMM Do YYYY";
export const SEARCH_DATE_FORMAT = "YYYY-MM-DD";
export const EXPORT_DATE_FORMAT = "DD_MM_YYYY";
export const SCHEDULED_DATE_FORMAT = "YYYY-MM-DD h:mm A";
export const DEFAULT_TAGS_COUNT = 20;

const WARNING_STATUSES = [];
const SUCCESS_STATUSED = ["Sent", "Subscribed"];
const INFO_STATUSES = ["Scheduled"];
const SECONDARY_STATUSES = ["Draft"];
const DANGER_STATUSES = [];

export const getStyle = (resource, column) => {
  if (WARNING_STATUSES.includes(resource[column])) {
    return "warning";
  } else if (SUCCESS_STATUSED.includes(resource[column])) {
    return "success";
  } else if (INFO_STATUSES.includes(resource[column])) {
    return "info";
  } else if (SECONDARY_STATUSES.includes(resource[column])) {
    return "secondary";
  } else if (DANGER_STATUSES.includes(resource[column])) {
    return "danger";
  } else {
    return "danger";
  }
};
