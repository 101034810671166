import React from "react";

import { LinkButton } from "@/components/commons/AppFormUI";
import Button from "@/images/button.png";

const ImageCard = ({ image }) => {
  return (
    <>
      {(image.src || image.file_data) && (
        <div className="flex flex-col items-center w-full space-y-6">
          <img
            src={image.src || image.file_data}
            className="w-full h-auto"
            alt={image.alt_text || "Take Image"}
          />

          <div
            className={`flex ${image.title?.length > 0 ? "justify-between" : "justify-end"} items-start w-full space-x-4`}
          >
            {image.title?.length > 0 && (
              <p
                className="pb-take--caption-text font-soehne-kraftig grow dark:text-white"
                dangerouslySetInnerHTML={{
                  __html: image.title?.split("\n")?.join("<br/>"),
                }}
              />
            )}

            {image.link?.length > 0 && !image.show_link_button && (
              <a href={image.link} target="_blank" className="shrink-0">
                <img
                  src={Button}
                  width="24px"
                  height="24px"
                  alt="link button"
                />
              </a>
            )}
          </div>
          {image.caption?.length > 0 && (
            <p
              className="pb-take--text w-full dark:text-white"
              dangerouslySetInnerHTML={{
                __html: image.caption?.split("\n")?.join("<br/>"),
              }}
            />
          )}
          {image.link?.length > 0 &&
            image.show_link_button &&
            image.button_text?.length > 0 && (
              <div className="flex justify-center !mt-8">
                <LinkButton
                  to={image.link}
                  label={image.button_text}
                  className="!w-fit !text-center"
                />
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default ImageCard;
