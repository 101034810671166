import React from "react";

import LinkCard from "@/components/SubscribeForm/LinkCard";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import CardHeader from "./CardHeader";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-[#FFC700] w-[140px] rounded-bl-lg rounded-br-lg">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const DesktopForm = ({
  user,
  hideControls,
  setSocialModalOpen,
  setLinkTreeModalOpen,
  ...otherProps
}) => {
  return (
    <div className="subscription-card-front flex flex-col items-center p-6 font-sf-pro-medium">
      <div className="rounded-2xl bg-white w-[480px]">
        <CardHeader user={user} hideControls={hideControls} {...otherProps} />

        <LinkCard
          user={user}
          hideControls={hideControls}
          setSocialModalOpen={setSocialModalOpen}
          setLinkTreeModalOpen={setLinkTreeModalOpen}
          brandingPage
        />
      </div>

      <Footer />
    </div>
  );
};

export default DesktopForm;
