import * as Yup from "yup";

import { MAX_STRING_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  subject: "",
  subject_preview: "",
  intro: "",
  outro: "",
  link_rolls_enabled: false,
  link_roll_title: "The B-Roll",
  link_roll_intro: "",
  attachments_attributes: [],
  link_rolls_attributes: [],
  draft: true,
  send_now: false,
  schedule: false,
  scheduled_at: "",
  subscribers_type: ["free", "paid"],
  header_image_type: "logo",
};

export const VALIDATION_SCHEMA = Yup.object({
  subject: Yup.string().required("Subject can't be blank."),
  draft: Yup.boolean(),
  schedule: Yup.boolean(),
  scheduled_at: Yup.mixed().when("schedule", {
    is: (val) => val == true,
    then: (schema) => schema.required("Schedule date can't be blank."),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const IMAGE_INITIAL_VALUE = {
  src: "",
  file_data: "",
  file_name: "",
  title: "",
  caption: "",
  link: "",
  alt_text: "",
  exclude_from_visually_archive: false,
  position: null,
  tag_ids: [],
  tags: [],
};

export const IMAGE_VALIDATION_SCHEMA = Yup.object({
  src: Yup.string(),
  file_data: Yup.string().when("src", {
    is: (val) => checkValue(val),
    then: (schema) => schema.required("Image can't be blank."),
    otherwise: (schema) => schema.notRequired(),
  }),
  link: Yup.string().nullable(),
  alt_text: Yup.string()
    .max(
      MAX_STRING_LENGTH,
      `Alt text must be at most ${MAX_STRING_LENGTH} characters.`,
    )
    .nullable(),
});

export const LINK_ROLL_INITIAL_VALUE = {
  caption: "",
  link: "",
  position: null,
  _destroy: false,
};

export const LINK_ROLL_VALIDATION_SCHEMA = Yup.object({
  link: Yup.string().required("Link can't be empty."),
});

export const HEADER_IMAGE_INITIAL_VALUE = {
  src: "",
  file_data: "",
  file_name: "",
  position: 1,
  attachable_kind: "take_header",
};

export const markRecordDeleted = (formik, position, attr) => {
  formik.setFieldValue(
    attr,
    formik.values[attr].map((record) => {
      if (record.position === position) {
        record._destroy = true;
      }

      return record;
    }),
  );
};

export const filterDeletedRecords = (records) => {
  return records.filter((record) => !record._destroy);
};

export const filterDeletedImages = (records) => {
  return records.filter((record) => !record.markedDeleted);
};

export const filterArchivedImages = (records) => {
  return records.filter((record) => !record.exclude_from_visually_archive);
};

export const orderRecords = (records) => {
  return records.sort((a, b) => a.position - b.position);
};

const checkValue = (val) => {
  val == "" || val == undefined || val == null;
};

//Rich Text Editor ToolbarSettings
export const RICH_TEXT_EDITOR_INLINE_MODE = {
  enable: true,
  onSelection: true,
};

export const RICH_TEXT_EDITOR_TOOLBAR = {
  items: ["Bold", "Italic", "Underline", "Alignments", "CreateLink"],
};
