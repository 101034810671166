import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

const SubscriberDropdown = ({
  formik,
  paidSubscribers = 100,
  freeSubscribers = 200,
  handleSubmitWithStatus,
}) => {
  const subscribersType = formik.values.subscribers_type;
  const [subscribersList, setSubscribersList] = useState([
    {
      label: "Free subscribers",
      type: "free",
      count: freeSubscribers,
    },
  ]);

  const buttonText = (type) => {
    if (type.length === 0) return "Select subscribers";
    if (type.includes("paid") && type.includes("free")) {
      return `All subscribers (${subscribersList.map((el) => el.count).reduce((sum, x) => sum + x)})`;
    } else {
      const data = subscribersList.find((el) => type.includes(el.type));
      return `${data.label} (${data.count})`;
    }
  };

  return (
    <Menu>
      <MenuButton className="inline-flex items-center rounded py-1 px-2 focus:outline-none data-[hover]:bg-[#F2EFEC] data-[open]:bg-[#F2EFEC]">
        {buttonText(subscribersType)}
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom start"
          className="origin-top-left w-[300px] rounded-lg border border-solid border-[#E5E0D9] bg-white p-2 shadow-lg focus:outline-none z-50"
        >
          {subscribersList.map((data, index) => {
            return (
              <MenuItem key={index}>
                <button
                  className="flex w-full items-center justify-between p-2 font-soehne-mono"
                  onClick={() => {
                    if (subscribersType.includes(data.type)) {
                      formik.setFieldValue(
                        "subscribers_type",
                        subscribersType.filter((type) => type != data.type),
                      );
                    } else {
                      formik.setFieldValue("subscribers_type", [
                        ...subscribersType,
                        data.type,
                      ]);
                    }
                    handleSubmitWithStatus();
                  }}
                >
                  <div className="flex items-center space-x-2 shrink-0">
                    {data.type === "paid" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="mr-2"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M13.867 9.75c-.246 -.48 -.708 -.769 -1.2 -.75h-1.334c-.736 0 -1.333 .67 -1.333 1.5c0 .827 .597 1.499 1.333 1.499h1.334c.736 0 1.333 .671 1.333 1.5c0 .828 -.597 1.499 -1.333 1.499h-1.334c-.492 .019 -.954 -.27 -1.2 -.75" />
                        <path d="M12 7v2" />
                        <path d="M12 15v2" />
                        <path d="M6 6l1.5 1.5" />
                        <path d="M16.5 16.5l1.5 1.5" />
                      </svg>
                    )}
                    {buttonText(data.type)}
                  </div>

                  {subscribersType.includes(data.type) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="ml-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="ml-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    </svg>
                  )}
                </button>
              </MenuItem>
            );
          })}
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default SubscriberDropdown;
