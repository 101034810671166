import React from "react";

import LinkCard from "@/components/SubscribeForm/LinkCard";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import CardHeader from "./CardHeader";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-[#FFC700]">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const MobileForm = ({
  user,
  coverImage,
  hideControls,
  setSocialModalOpen,
  setLinkTreeModalOpen,
  ...otherProps
}) => {
  return (
    <div className="subscription-card-front font-sf-pro-medium">
      <div className="h-[200px]">
        <img
          src={coverImage?.file_data || coverImage?.src}
          className="h-full w-full object-cover"
        />
      </div>

      <CardHeader
        user={user}
        hideControls={hideControls}
        mobileForm
        {...otherProps}
      />

      <div className="bg-white">
        <LinkCard
          user={user}
          hideControls={hideControls}
          setSocialModalOpen={setSocialModalOpen}
          setLinkTreeModalOpen={setLinkTreeModalOpen}
          brandingPage
          mobileForm
        />
      </div>
      <Footer />
    </div>
  );
};

export default MobileForm;
