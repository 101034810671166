import axios from "axios";

export const getTakeList = (url_slug, tagIds, pageIndex) => {
  let searchQuery = "";
  searchQuery += `q[tags_id_in]=${tagIds}`;
  return axios.get(
    `/api/v1/visual_archives?url_slug=${url_slug}&${searchQuery}&page=${pageIndex}`,
  );
};

export const getTagList = (url_slug, pageIndex) => {
  return axios.get(
    `/api/v1/visual_archives/tags?url_slug=${url_slug}&page=${pageIndex}`,
  );
};
