import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as pluralize from "pluralize";
import { Toastr, Dropdown, Modal } from "@bigbinary/neetoui";
import { ImageUpload, Upload, Delete } from "@bigbinary/neeto-icons";
import LazyLoad from "react-lazy-load";

import { destroyAttachment } from "@/apis/settings/attachments";
import { createSubscription } from "@/apis/subscriptions";
import { showToastrError } from "@/components/commons";
import { Button, InputWithButton } from "@/components/commons/AppFormUI";

import ImageUploader from "./ImageUploader";
import { INITIAL_VALUE } from "./constants";

const Banner = ({
  takeUser,
  takesCount,
  urlSlug,
  user,
  setPageLoader,
  getTakeListResponse,
}) => {
  const { Menu, MenuItem, Divider } = Dropdown;
  const selfAccount = urlSlug === user?.url_slug;
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [archiveCoverImage, setArchiveCoverImage] = useState({
    id: takeUser.archive_cover_image_id,
    src:
      takeUser.archive_cover_image_url?.length > 0 &&
      takeUser.archive_cover_image_url !== undefined
        ? takeUser.archive_cover_image_url
        : takeUser.cover_image_url,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user ? { email: user.email } : INITIAL_VALUE,
    onSubmit: () => createSubscriptionResponse(),
  });

  useEffect(() => {
    if (takeUser) {
      setArchiveCoverImage({
        id: takeUser.archive_cover_image_id,
        src:
          takeUser.archive_cover_image_url?.length > 0 &&
          takeUser.archive_cover_image_url !== undefined
            ? takeUser.archive_cover_image_url
            : takeUser.cover_image_url,
      });
    }
  }, [takeUser]);

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: formik.values.email, user_id: takeUser.id },
      });
      Toastr.success(data.message);
      formik.resetForm();
      setButtonStatus(true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const destroyAttachmentsResponse = async () => {
    try {
      setBtnLoader(true);
      await destroyAttachment(archiveCoverImage?.id);
      Toastr.success("Cover image has been successfully deleted.");
      setDeleteAlertOpen(false);
      await getTakeListResponse([], 1, true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="mb-10 md:mb-16 w-full">
      <div className="relative h-20 w-full rounded-lg md:h-32 lg:h-72 lg:rounded-2xl">
        <LazyLoad threshold={0.1}>
          <img
            src={archiveCoverImage.src}
            className="h-full w-full rounded-lg object-cover lg:rounded-2xl"
          />
        </LazyLoad>

        <div className="h-12 w-12 md:h-20 md:w-20 lg:h-36 lg:w-36 absolute inset-x-0 -bottom-[24px] m-auto rounded-full border-2 border-solid border-secondary md:-bottom-[40px] lg:-bottom-[92px] lg:left-6 lg:m-0 lg:border-4">
          <LazyLoad threshold={0.1}>
            <img
              src={takeUser.logo_url}
              className="w-full h-full object-cover rounded-full"
            />
          </LazyLoad>
        </div>

        {user?.id === takeUser.id && (
          <div className="absolute right-3 bottom-3 lg:right-6 lg:bottom-6 cursor-pointer">
            <Dropdown
              label="Dropdown"
              closeOnSelect={false}
              customTarget={
                <div className="flex items-center gap-x-3 px-2 sm:px-4 py-1.5 rounded-xl bg-white w-fit">
                  <ImageUpload size={16} />
                  <span className="sm:hidden">Edit</span>
                  <span className="hidden sm:block">Edit cover image</span>
                </div>
              }
            >
              <Menu className="w-[230px]">
                {archiveCoverImage?.id ? (
                  <MenuItem.Button
                    className="flex items-center gap-x-3"
                    onClick={() => setDeleteAlertOpen(true)}
                  >
                    <Delete size={16} />
                    <span>Remove</span>
                  </MenuItem.Button>
                ) : (
                  <>
                    <MenuItem.Button>
                      <div className="reative">
                        <div className="flex items-center gap-x-3">
                          <Upload size={16} />
                          <span>Upload</span>
                        </div>

                        <ImageUploader
                          type={"archive_cover"}
                          position={3}
                          setPageLoader={setPageLoader}
                          getTakeListResponse={getTakeListResponse}
                        />
                      </div>
                    </MenuItem.Button>
                    <Divider />
                    <MenuItem.Button className="hover:!bg-white !cursor-text flex flex-col text-left font-sf-pro font-[12px] leading-0">
                      For best results, we recommend an
                      <br />
                      image size of at least 1440 x 280
                    </MenuItem.Button>
                  </>
                )}
              </Menu>
            </Dropdown>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center justify-between pl-0 mt-10 gap-3 lg:items-start lg:mt-5 lg:flex-row lg:pl-48">
        <div className="flex flex-col w-full text-center gap-2 lg:w-fit lg:text-left">
          <h3 className="text-[24px] font-medium text-lightBlack lg:text-[32px] font-soehne-kraftig">
            {takeUser.name}
          </h3>
          <span className="text-base font-normal leading-5 text-black opacity-60">
            {takesCount} {pluralize("take", takesCount)}
            {!!takeUser.personal_url && (
              <>
                {" • "}
                <a target="_blank" href={takeUser.personal_url}>
                  {takeUser.personal_url}
                </a>
              </>
            )}
          </span>
        </div>

        <div className="">
          {user && !selfAccount && (
            <Button
              onClick={() => (takeUser.subscribed ? {} : formik.handleSubmit())}
              label={
                takeUser.subscribed || buttonStatus ? "Subscribed" : "Subscribe"
              }
              loading={takeUser.subscribed || buttonStatus ? null : btnLoader}
              style={takeUser.subscribed || buttonStatus ? "link" : "primary"}
              className="w-20"
            />
          )}

          {!user && !takeUser.subscribed && (
            <InputWithButton
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onSubmit={formik.handleSubmit}
              loading={btnLoader}
              label="Subscribe"
              className="w-full max-w-[398px]"
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={deleteAlertOpen}
        onClose={() => setDeleteAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Destroy Cover Image</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to remove the cover image?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            loading={btnLoader}
            label="Yes, Destroy"
            type="submit"
            style="danger"
            onClick={() => destroyAttachmentsResponse()}
          />

          <Button
            label="Cancel"
            onClick={() => setDeleteAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Banner;
