import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Toastr } from "@bigbinary/neetoui";

import { createSubscription } from "@/apis/subscriptions";
import { MAX_STRING_LENGTH } from "@/lib/constants";
import { showToastrError } from "@/components/commons";
import { Input, Button } from "@/components/commons/SettingsFormUI";

const FormCardFront = ({ user, setSubscribed, contentRef }) => {
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    onSubmit: () => createSubscriptionResponse(),
  });

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: formik.values.email, user_id: user.id },
      });
      setSubscribed(true);
      Toastr.success(data.message);
      formik.resetForm();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full p-6 gap-y-6 sm:px-12 sm:pt-12">
      <div className="flex flex-col items-center w-full">
        <div className="overflow-hidden rounded-full">
          <img
            src={user.logo_url}
            alt="artist icon"
            className="object-cover h-12 w-12"
          />
        </div>
      </div>

      <div className="text-[28px] leading-[33.41px] text-center font-soehne-kraftig sm:text-[32px] sm:leading-[38.19px]">
        {user.display_name}
      </div>

      {user.welcome_message?.length > 0 && (
        <p
          className="text-[16px] leading-[24px] text-center m-0"
          ref={contentRef}
        >
          {user.welcome_message}
        </p>
      )}

      <div className="flex gap-x-3 w-full">
        <Input
          id="email"
          name="email"
          type="email"
          className="!text-[16px]"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Enter email address"
          maxLength={MAX_STRING_LENGTH}
        />

        <Button
          onClick={() => formik.handleSubmit()}
          type="submit"
          label="Subscribe"
          loading={btnLoader}
          loginBtn
        />
      </div>

      <div className="px-[48px] py-[12px]">
        <Link
          to={`/archive/${user.url_slug}`}
          className="text-[16px] leading-[24px] font-medium"
          onClick={() => window.scrollTo(0, 0)}
        >
          View archives
        </Link>
      </div>
    </div>
  );
};

export default FormCardFront;
