import React from "react";
import * as pluralize from "pluralize";
import LazyLoad from "react-lazy-load";

const BoothCard = ({ boothUser }) => {
  const firstAttachment = boothUser.attachments[0];
  const attachments = boothUser.attachments;

  return (
    <div className="pb-featured-booth-card">
      <div className="pb-featured-booth-card--img-wrapper grid grid-cols-2 gap-0.5 overflow-hidden rounded-lg">
        <img src={firstAttachment.thumb_src} />
        <div className="grid grid-cols-2 gap-0.5">
          {Array.from(
            { length: boothUser.attachments.length - 1 },
            (_, i) => i + 1,
          ).map((index) => {
            return (
              <LazyLoad key={attachments[index].id} threshold={0.1}>
                <img
                  src={attachments[index].thumb_src}
                  className="aspect-square object-cover"
                />
              </LazyLoad>
            );
          })}
        </div>
      </div>
      <div className="flex items-center gap-x-3">
        <div className="h-10 w-10 overflow-hidden rounded-full border-2 border-black bg-black shrink-0">
          <img className="w-full" src={boothUser.logo_url} />
        </div>
        <div className="flex flex-col items-start">
          <h4 className="text-base font-medium leading-normal text-black line-clamp-1">
            {boothUser.name}
          </h4>
          <div className="flex items-center gap-x-1 text-xs font-normal leading-4 text-black opacity-60">
            {boothUser.location && (
              <span className="shrink line-clamp-1">{boothUser.location}</span>
            )}
            {boothUser.location && (
              <span className="shrink-0">{" • "}</span>
            )}
            <span className="shrink-0">{boothUser.takes_count} {pluralize("take", boothUser.takes_count)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoothCard;
