import React, { useState, useEffect, useRef } from "react";

import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import FormCardFront from "./FormCardFront";
import FormCardBack from "./FormCardBack";
import LinkCard from "./LinkCard";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-[#FFC700] w-[140px] rounded-bl-lg rounded-br-lg">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const DesktopForm = ({ user }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [height, setHeight] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setImageHeight(`${height + 24 + 48}px`);
  }, [height]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(contentRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const coverPictureStyles = {
    backgroundImage: `url(${user.cover_image_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
  };

  return (
    <div
      className="pb-settings--wrapper flex-col items-center h-screen w-screen overflow-y-auto font-sf-pro-medium relative subscription-form hidden sm:flex"
      style={coverPictureStyles}
    >
      <div
        className={`subscription-card-container ${
          subscribed ? "subscribed" : ""
        }`}
      >
        <div className="subscription-card-front flex flex-col items-center justify-center min-h-screen py-12">
          <div className="rounded-2xl bg-white w-[480px]">
            <FormCardFront
              user={user}
              setSubscribed={setSubscribed}
              contentRef={contentRef}
            />
            {(user.link_trees.length > 0 || user.social_links_present) && (
              <LinkCard user={user} subscriberForm />
            )}
          </div>

          <Footer />
        </div>

        <div className="subscription-card-back flex flex-col items-center justify-center min-h-screen py-12">
          <div className="rounded-2xl bg-white w-[480px]">
            <FormCardBack user={user} imageHeight={imageHeight} />
            {(user.link_trees.length > 0 || user.social_links_present) && (
              <LinkCard user={user} subscriberForm />
            )}
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DesktopForm;
