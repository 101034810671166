import React, { useEffect, useState } from "react";
import { Spinner } from "@bigbinary/neetoui";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Loading } from "@bigbinary/neeto-icons";

import { getTakeList } from "@/apis/discoveries";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import TakeCard from "@/components/commons/TakeCard";

const Discovery = () => {
  const [takeList, setTakeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    getTakeListResponse();
  }, []);

  const getTakeListResponse = async () => {
    try {
      const { data } = await getTakeList(pageIndex + 1);
      setTakeList([...takeList, ...data.takes]);
      setTotalRecords(data.total_records);
      setPageIndex(pageIndex + 1);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout flex flex-col w-full">
        <InfiniteScroll
          dataLength={takeList.length}
          next={getTakeListResponse}
          hasMore={totalRecords > takeList.length}
          loader={<div className="w-full flex justify-center p-6">Loading... <Loading className="animate-spin-slow ml-2" /></div>}
          scrollThreshold={0.5}
        >
          <ResponsiveMasonry columnsCountBreakPoints={{ 1: 1, 767: 2, 1199: 3, 1399: 4 }}>
            <Masonry gutter="1.5rem">
              {takeList.map((take) => {
                return (
                  <TakeCard
                    key={take.id}
                    take={take}
                    logo_url={take.logo_url}
                    discoveryPage
                    setTakeList={setTakeList}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      </AnimatedPage>
    </div>
  );
};

export default Discovery;
