import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Spinner } from "@bigbinary/neetoui";
import { useParams, useNavigate } from "react-router-dom";

import { useAuthContext } from "@/lib/useAuthContext";

import { getTake } from "@/apis/takes";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import Header from "@/components/commons/Header";
import AppHeader from "@/components/commons/AppHeader";
import TakeDetails from "./TakeDetails";
import ApprovalBanner from "./ApprovalBanner";
import { filterArchivedImages } from "../CreateTake/constants";

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [takeDetail, setTakeDetail] = useState();
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (id) {
      getTakeDetailResponse();
    }
  }, [id]);

  const getTakeDetailResponse = async () => {
    try {
      const { data } = await getTake(id);
      setTakeDetail(data.take);
      setPageLoader(false);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/not_found");
      }
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {user && <AppHeader />}
      <div
        className={classnames("flex flex-col items-center w-full px-6", {
          "pb-app--layout-wrapper pt-[64px] md:pt-[98px]": user,
          "pb-home--layout-wrapper": !user,
        })}
      >
        {!user && <Header />}

        <AnimatedPage className="pb-app--layout flex flex-col items-center w-full max-w-[960px] bg-white rounded-lg mt-6 mb-12 !p-0 shadow-lg">
          <TakeDetails
            take={takeDetail}
            user={takeDetail.user}
            subscriptions={takeDetail.subscriptions}
            attachments={filterArchivedImages(
              takeDetail.attachments_attributes,
            )}
            linkRolls={takeDetail.link_rolls_attributes}
          />
        </AnimatedPage>

        {user && user.superadmin && takeDetail.under_review && (
          <ApprovalBanner
            takeDetail={takeDetail}
            setTakeDetail={setTakeDetail}
          />
        )}
      </div>
    </>
  );
};

export default Detail;
