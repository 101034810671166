import React, { useState } from "react";
import * as pluralize from "pluralize";
import { useFormik } from "formik";
import LazyLoad from "react-lazy-load";

import { Toastr } from "@bigbinary/neetoui";
import { useAuthContext } from "@/lib/useAuthContext";
import { createSubscription } from "@/apis/subscriptions";
import { showToastrError } from "@/components/commons";
import {
  Button,
  LinkButton,
  InputWithButton,
} from "@/components/commons/AppFormUI";

import { INITIAL_VALUE } from "./constants";

const BoothCard = ({ boothUser, handleSubscription }) => {
  const { user } = useAuthContext();
  const [btnLoader, setBtnLoader] = useState(false);
  const [emailField, setEmailField] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user ? { email: user.email } : INITIAL_VALUE,
    onSubmit: () => {
      createSubscriptionResponse();
    },
  });

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      let payload = {
        subscription: { email: formik.values.email, user_id: boothUser.id },
      };
      const { data } = await createSubscription(payload);
      Toastr.success(data.message);
      handleSubscription(boothUser.id);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col w-full gap-y-4 rounded-2xl bg-white p-4">
      <div className="grid grid-cols-2 gap-0.5 rounded-2xl overflow-hidden flex-grow">
        {boothUser.attachments.map((attachment) => {
          return (
            <LazyLoad key={attachment.id} threshold={0.1}>
              <img
                src={attachment.thumb_src}
                className="aspect-square object-cover"
              />
            </LazyLoad>
          );
        })}
      </div>

      <div className="flex items-center gap-x-3">
        <div className="h-10 w-10 overflow-hidden rounded-full border-2 border-black shrink-0">
          <LazyLoad threshold={0.1}>
            <img
              src={boothUser.logo_url}
              className="aspect-square object-cover"
            />
          </LazyLoad>
        </div>
        <div className="flex flex-col items-start">
          <h4 className="text-base font-medium leading-normal line-clamp-1">
            {boothUser.name}
          </h4>
          <div className="flex items-center gap-x-1 text-xs font-normal leading-4 opacity-60">
            {boothUser.location && (
              <>
                <span>{boothUser.location}</span>
                <span>{" • "}</span>
              </>
            )}
            <span>
              {boothUser.takes_count} {pluralize("take", boothUser.takes_count)}
            </span>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-y-3">
        <LinkButton
          to={`/archive/${boothUser.url_slug}`}
          label="View Booth"
          style="secondary"
          onClick={() => window.scrollTo(0, 0)}
        />

        {user && (
          <Button
            onClick={() => (boothUser.subscribed ? {} : formik.handleSubmit())}
            label={boothUser.subscribed ? "Subscribed" : "Subscribe"}
            loading={boothUser.subscribed ? null : btnLoader}
            style={boothUser.subscribed ? "link" : "primary"}
          />
        )}

        {!user && !emailField && (
          <Button onClick={() => setEmailField(true)} label="Subscribe" />
        )}

        {!user && emailField && !boothUser.subscribed && (
          <InputWithButton
            id="email"
            name="email"
            type="email"
            placeholder="Enter email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onSubmit={formik.handleSubmit}
            loading={btnLoader}
            label="Subscribe"
          />
        )}

        {!user && emailField && boothUser.subscribed && (
          <Button onClick={() => {}} label="Subscribed" style="link" />
        )}
      </div>
    </div>
  );
};

export default BoothCard;
