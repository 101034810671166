import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";
import { useParams, useNavigate } from "react-router-dom";

import { getSubscriberFormSetting } from "@/apis/subscriber_form_settings";
import { showToastrError } from "@/components/commons";

import MobileForm from "./MobileForm";
import DesktopForm from "./DesktopForm";

const SubscribeForm = () => {
  const { urlSlug } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    getSubscriberFormSettingResponse();
  }, []);

  const getSubscriberFormSettingResponse = async () => {
    try {
      const response = await getSubscriberFormSetting(urlSlug);
      setUser(response.data.user);
      setPageLoader(false);
    } catch (error) {
      navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="subscription-form">
      <MobileForm user={user} />
      <DesktopForm user={user} />
    </div>
  );
};

export default SubscribeForm;
