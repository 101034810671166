import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import classnames from "classnames";
import { Loading } from "@bigbinary/neeto-icons";

const Button = ({
  className = "",
  label = "Button",
  loading = false,
  style = "primary",
  disabled = false,
  ...otherProps
}) => {
  const btnClassNames = classnames(
    "pb-settings--button",
    {
      "pb-settings--button--primary": style === "primary",
      "pb-settings--button--danger": style === "danger",
      "pb-settings--button--secondary": style === "secondary",
    },
    { "pointer-events-none": loading },
    { "disabled-btn": disabled },
  );

  return (
    <button {...otherProps} className={classnames([btnClassNames, className])}>
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{ width: "auto", scale: 1 }}
            exit={{ width: 0, scale: 0 }}
            initial={{ width: 0, scale: 0 }}
            transition={{ bounce: false }}
          >
            <Loading className="mr-2 animate-spin-slow" />
          </motion.div>
        )}
      </AnimatePresence>

      {label}
    </button>
  );
};

export default Button;
