import React, { useState, useEffect } from "react";
import { Spinner, Callout, Modal } from "@bigbinary/neetoui";
import { Warning } from "@bigbinary/neeto-icons";

import { getBilling, cancelBilling } from "@/apis/settings/billings";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { LinkButton, Button } from "@/components/commons/HomeFormUI";
import { Button as ModalButton } from "@/components/commons/AppFormUI";

import PaymentDetails from "./PaymentDetails";
import BillingHistory from "./BillingHistory";
import DesktopPricing from "./DesktopPricing";
import MobilePricing from "./MobilePricing";

const Billing = () => {
  const [billing, setBilling] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [cancelBillingAlert, setCancelBillingAlert] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [activeStripePlan, setActiveStripePlan] = useState();
  const [reactivationNeeded, setReactivationNeeded] = useState(false);

  useEffect(() => {
    getBillingResponse();
  }, []);

  const getBillingResponse = async () => {
    try {
      const { data } = await getBilling();
      setBilling(data.billing);
      setActiveStripePlan(
        data.billing.stripe_plans.find(
          (plan) => plan.id === data.billing.stripe_plan_id,
        ),
      );
      setReactivationNeeded(
        data.billing.plan_subscription_needed &&
          data.billing.stripe_subscription?.cancelled_at,
      );
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const cancelBillingResponse = async () => {
    try {
      setBtnLoader(true);
      const response = await cancelBilling(billing.stripe_subscription.id);
      setBtnLoader(false);
      setPageLoader(true);
      getBillingResponse();
      setCancelBillingAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full gap-y-8">
        {billing.vip ? (
          <div className="flex flex-col w-full rounded-lg p-6 space-y-3 bg-white divide-y divide-gray-300">
            <div className="flex flex-col w-full">
              <h4 className="text-[16px] leading-[19.06px] m-0 font-sf-pro font-semibold mb-1">
                Current plan
              </h4>

              <h2 className="text-[24px] leading-[28.64px] m-0">VIP</h2>
            </div>

            <p className="text-[16px] leading-[19.06px] pt-3 m-0">
              You must be someone quite special! You have full and unlimited
              access to PencilBooth for literally zero dollars. Be free and
              email away to your heart’s content without restriction!
            </p>
          </div>
        ) : (
          <>
            <DesktopPricing
              activePlan={activeStripePlan}
              subscriberCount={billing.subscriber_count}
              completed={billing.completed_percentage}
              billing={billing}
              cancelBillingAlert={cancelBillingAlert}
              setCancelBillingAlert={setCancelBillingAlert}
            />
            <MobilePricing
              activePlan={activeStripePlan}
              subscriberCount={billing.subscriber_count}
              completed={billing.completed_percentage}
              billing={billing}
              cancelBillingAlert={cancelBillingAlert}
              setCancelBillingAlert={setCancelBillingAlert}
            />

            <div className="text-[12px] lg:text-[14px]">
              <p className="m-0">
                * All plans include a maximum of one email per day to your
                entire list. If you need to send more frequently, please drop us
                a line.
              </p>
              <p className="m-0">** Coming Soon</p>
              <p className="m-0">*** Mysterious!!</p>
            </div>

            <Modal
              isOpen={cancelBillingAlert}
              onClose={() => setCancelBillingAlert(false)}
              className="pb-alert"
            >
              <Modal.Header>
                <h4>Cancel Billing</h4>
              </Modal.Header>
              <Modal.Body className="space-y-4">
                <p>Are you sure you want to cancel the billing?</p>
              </Modal.Body>
              <Modal.Footer className="flex gap-x-2">
                <ModalButton
                  label="Yes, Cancel"
                  type="submit"
                  style="danger"
                  onClick={() => cancelBillingResponse()}
                  loading={btnLoader}
                />
                <ModalButton
                  label="Cancel"
                  onClick={() => setCancelBillingAlert(false)}
                  style="modal"
                />
              </Modal.Footer>
            </Modal>
          </>
        )}
      </AnimatedPage>
    </div>
  );
};

export default Billing;
