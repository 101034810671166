import React, { useState, useEffect, useRef } from "react";

import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import FormCardFront from "./FormCardFront";
import FormCardBack from "./FormCardBack";
import LinkCard from "./LinkCard";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-[#FFC700]">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const MobileForm = ({ user }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [height, setHeight] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setImageHeight(`${height + 24 + 48}px`);
  }, [height]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(contentRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const coverPictureStyles = {
    backgroundImage: `url(${user.cover_image_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };

  return (
    <div className="pb-settings--wrapper flex flex-col items-center min-h-screen font-sf-pro-medium relative subscription-form sm:hidden">
      <div className="h-[200px] w-full" style={coverPictureStyles}></div>

      <div
        className={`subscription-card-container ${
          subscribed ? "subscribed" : ""
        }`}
      >
        <div className="subscription-card-front bg-white">
          <FormCardFront
            user={user}
            setSubscribed={setSubscribed}
            contentRef={contentRef}
          />
          {(user.link_trees.length > 0 || user.social_links_present) && (
            <LinkCard user={user} subscriberForm />
          )}
          <Footer />
        </div>

        <div className="subscription-card-back bg-white">
          <FormCardBack user={user} imageHeight={imageHeight} />
          {(user.link_trees.length > 0 || user.social_links_present) && (
            <LinkCard user={user} subscriberForm />
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MobileForm;
