import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import classnames from "classnames";
import { Popover } from "antd";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Loading } from "@bigbinary/neeto-icons";
import { Switch } from "@/components/commons/SettingsFormUI";
import AsyncPaginateSelect from "@/components/commons/AsyncPaginateSelect";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import {
  MenuHorizontal,
  ALTIcon,
  HeroImageIcon,
  TagIcon,
  LinkIcon,
  MoveUpIcon,
  MoveDownIcon,
  TrashIcon,
  EyeIcon,
  CloseEyeIcon,
  DisableMoveDownIcon,
  DisableMoveUpIcon,
} from "@/components/commons/PBIcons";
import { updateAttachment, destroyAttachment } from "@/apis/take_attachments";
import { getTags, createTag } from "@/apis/tags";
import {
  dropDownListGenerator,
  getUnique,
  isValidUrl,
  withoutProtocol,
} from "@/lib/utils";
import { Button, LinkButton } from "@/components/commons/AppFormUI";
import { showToastrError } from "@/components/commons";
import {
  RICH_TEXT_EDITOR_INLINE_MODE,
  RICH_TEXT_EDITOR_TOOLBAR,
} from "./../../constants";

const TakeImage = ({
  attachment,
  formik,
  reorderAttachmentResponse,
  createAttachmentWithTake,
  createAttachmentResponse,
}) => {
  const imageRef = useRef(null);
  const [captionText, setCaptionText] = useState("");
  const [attachmentData, setAttachmentData] = useState(attachment || {});
  const [clicked, setClicked] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [link, setLink] = useState();
  const [altText, setAltText] = useState();
  const [buttonText, setButtonText] = useState("");
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [tagListLoad, setTagListLoad] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [initial, setInitial] = useState(true);
  const [excludeFromArchive, setExcludeFromArchive] = useState(false);
  const [heroImage, setHeroImage] = useState(false);
  const [linkPopover, setLinkPopover] = useState(false);
  const [altPopover, setAltPopover] = useState(false);
  const [tagPopover, setTagPopover] = useState(false);
  const [deletePopover, setDeletePopover] = useState(false);
  const [linkPillPopover, setLinkPillPopover] = useState(false);
  const [altPillPopover, setAltPillPopover] = useState(false);
  const [tagPillPopover, setTagPillPopover] = useState(false);
  const [linkError, setLinkError] = useState();

  useEffect(() => {
    if (initial) {
      setTitleText(attachment.title);
      setAltText(attachment.alt_text);
      setLink(attachment.link);
      setTags(attachment.tag_ids);
      setExcludeFromArchive(attachment.exclude_from_visually_archive);
      setHeroImage(attachment.hero_image);
      setCaptionText(attachment.caption);
      setTagList(attachment.tags);
      setShowLinkButton(attachment.show_link_button);
      setButtonText(attachment.button_text);
      setAttachmentData(attachment);
    }
  }, [attachment]);

  useEffect(() => {
    if (!initial) {
      updateAttachmentResponse();
    }
  }, [excludeFromArchive]);

  useEffect(() => {
    if (!initial) updateLinkButtonToggleResponse();
  }, [showLinkButton]);

  useEffect(() => {
    if (link?.length === 0) {
      setButtonText("");
      setShowLinkButton(false);
    }
  }, [link]);

  // To add eventlistner to image card to capture click event
  useEffect(() => {
    if (imageRef.current !== null) {
      const handleClick = (event) => {
        if (!imageRef.current.contains(event.target)) {
          setClicked(false);
        }
      };
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [imageRef]);

  // This to create new attachment
  useEffect(() => {
    if (!attachment.id && attachment.imageLoader) {
      if (!formik.values.id) {
        createAttachmentWithTake(attachment);
      } else {
        createAttachmentResponse(attachment, formik.values.id, false);
      }
    }
  }, [attachment]);

  const attachmentLink = (website) => {
    if (website?.length > 0 && isValidUrl(website)) {
      if (withoutProtocol(website)) {
        return `http://${website}`;
      } else {
        return website;
      }
    }
  };

  const updateAttachmentResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateAttachment(formik.values.id, attachment.id, {
        attachment: {
          alt_text: altText,
          title: titleText,
          caption: captionText,
          link: attachmentLink(link) || null,
          tag_ids: tags,
          exclude_from_visually_archive: excludeFromArchive,
          show_link_button: showLinkButton,
          button_text: buttonText,
        },
      });
      setAttachmentData(data.attachment);

      const updatedAttachments = formik.values.attachments_attributes.map(
        (att) => {
          if (att.id === attachment.id) {
            return { ...data.attachment };
          } else {
            return { ...att };
          }
        },
      );

      formik.setFieldValue("attachments_attributes", updatedAttachments);
      setLinkPopover(false);
      setAltPopover(false);
      setTagPopover(false);
      setLinkPillPopover(false);
      setAltPillPopover(false);
      setTagPillPopover(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setInitial(true);
    }
  };

  const updateLinkButtonToggleResponse = async () => {
    try {
      await updateAttachment(formik.values.id, attachment.id, {
        attachment: {
          show_link_button: showLinkButton,
        },
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setInitial(true);
    }
  };

  const updateHeroImageAttachmentResponse = async (value) => {
    try {
      const { data } = await updateAttachment(formik.values.id, attachment.id, {
        attachment: { hero_image: value },
      });

      const updatedAttachments = formik.values.attachments_attributes.map(
        (att) => {
          if (att.id === attachment.id) {
            return { ...data.attachment };
          } else {
            return { ...att, hero_image: false };
          }
        },
      );
      setInitial(true);
      formik.setFieldValue("attachments_attributes", updatedAttachments);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getTagsResponse = async (search, _loadedOptions, additional) => {
    try {
      setTagListLoad(true);
      const pageNumber = additional?.page || 1;

      const { data } = await getTags(search, pageNumber);
      const responseData = dropDownListGenerator(data.tags, "name");
      const dataList = getUnique([...tagList, ...responseData], "value");

      setTagList(dataList);

      return {
        options: responseData,
        hasMore: data.total_records > dataList.length,
        additional: {
          page: pageNumber + 1,
        },
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setTagListLoad(false);
    }
  };

  const createTagResponse = async (tagName) => {
    try {
      const { data } = await createTag({ tag: { name: tagName } });
      setTagList([
        {
          label: data.tag.name,
          value: data.tag.id,
        },
        ...tagList,
      ]);
      setTags([...tags, data.tag.id]);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyAttachmentResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyAttachment(formik.values.id, attachment.id);
      formik.setFieldValue("attachments_attributes", data.attachments);
      setDeletePopover(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const hasContent = () => {
    return (
      titleText?.length > 0 ||
      captionText?.length > 0 ||
      attachmentData.show_link_button ||
      clicked
    );
  };

  const isPillActive = () => {
    return (
      attachmentData.tags?.length > 0 ||
      attachmentData.link?.length > 0 ||
      attachmentData.alt_text?.length > 0
    );
  };

  return (
    <div className="relative mb-50 w-full" ref={imageRef}>
      <div className="relative">
        <img
          src={attachment.src || attachment.file_data}
          className={classnames("pb-take-image cursor-pointer", {
            "pb-take-image--selected": clicked,
          })}
          alt={altText}
          onClick={() => setClicked(true)}
        />

        {!hasContent() && (
          <div className="absolute bottom-2 left-2 sm:bottom-5 sm:left-5">
            <motion.div
              animate={{
                opacity: 1,
                height: !hasContent() ? "auto" : 0,
                overflow: !hasContent() ? "visible" : "hidden",
              }}
              exit={{ opacity: 0, height: 0 }}
              initial={{ opacity: 0, height: 0, overflow: "hidden" }}
              transition={{ duration: 0.3 }}
            >
              <span className="text-white bg-black px-4 py-1.5 rounded-full text-[10px] sm:text-base">
                Click image to add title and caption
              </span>
            </motion.div>
          </div>
        )}
      </div>

      {isPillActive() && (
        <div className="absolute left-5 top-5 hidden sm:block">
          <div className="flex items-center gap-2.5">
            {attachmentData.link?.length > 0 && (
              <Popover
                content={
                  <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                    <div className="flex flex-col space-y-2 w-full">
                      <span className="text-[14px]">Link</span>
                      <input
                        value={link}
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                        className="pb-take-popover-input"
                        placeholder="Enter a link"
                      />
                      <p className="text-sm text-[#CB4446]">{linkError}</p>
                    </div>

                    {link && (
                      <div className="flex justify-between gap-x-3">
                        <label className="text-[14px] m-0">
                          Show link button
                        </label>
                        <div className="w-[45px]">
                          <Switch
                            checked={showLinkButton}
                            onChange={() => {
                              setInitial(false);
                              setShowLinkButton(!showLinkButton);
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {link && showLinkButton && (
                      <div className="flex flex-col space-y-2 w-full">
                        <span className="text-[14px]">Button Text</span>
                        <input
                          value={buttonText}
                          onChange={(e) => setButtonText(e.target.value)}
                          className="pb-take-popover-input"
                          placeholder="Add display text"
                        />
                      </div>
                    )}

                    <div className="flex justify-end space-x-4">
                      <Button
                        label="Cancel"
                        className="!w-fit"
                        onClick={() => setLinkPillPopover(false)}
                        style="link"
                      />

                      <Button
                        label="Insert"
                        loading={btnLoader}
                        className="!w-fit"
                        type="submit"
                        onClick={() => {
                          if (link?.length > 0 && !isValidUrl(link)) {
                            setLinkError("Link is not valid.");
                          } else {
                            setLinkError("");
                            updateAttachmentResponse();
                          }
                        }}
                      />
                    </div>
                  </div>
                }
                trigger="click"
                open={linkPillPopover}
                placement="bottom"
                overlayClassName="w-[350px]"
              >
                <span
                  className="px-4 py-1.5 text-sm uppercase rounded-full bg-white shadow-md cursor-pointer"
                  onClick={() => {
                    setLinkPillPopover(true);
                    setAltPillPopover(false);
                    setTagPillPopover(false);
                  }}
                >
                  Link
                </span>
              </Popover>
            )}

            {attachmentData.alt_text?.length > 0 && (
              <Popover
                content={
                  <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                    <div className="flex flex-col space-y-2 w-full">
                      <span className="text-[14px] h-full">Alt Text</span>
                      <input
                        value={altText}
                        onChange={(e) => setAltText(e.target.value)}
                        className="pb-take-popover-input"
                        placeholder="Enter a alt text"
                      />
                      <p className="m-0 text-[#7B7E82] text-sm">
                        Add a short written description of your image. This
                        allows your images to be accessible to those who are
                        using a screen reader, are vision impaired or blind.
                      </p>
                    </div>

                    <div className="flex justify-end space-x-4">
                      <Button
                        label="Cancel"
                        className="!w-fit"
                        onClick={() => setAltPillPopover(false)}
                        style="link"
                      />

                      <Button
                        label="Save"
                        loading={btnLoader}
                        className="!w-fit"
                        type="submit"
                        onClick={() => updateAttachmentResponse()}
                      />
                    </div>
                  </div>
                }
                trigger="click"
                open={altPillPopover}
                placement="bottom"
                overlayClassName="w-[350px]"
              >
                <span
                  className="px-4 py-1.5 text-sm uppercase rounded-full bg-white shadow-md cursor-pointer"
                  onClick={() => {
                    setLinkPillPopover(false);
                    setAltPillPopover(true);
                    setTagPillPopover(false);
                  }}
                >
                  Alt
                </span>
              </Popover>
            )}

            {attachmentData.tags?.length > 0 && (
              <Popover
                content={
                  <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                    <div className="flex flex-col space-y-2 w-full">
                      <span className="text-[14px]">Tags</span>
                      <AsyncPaginateSelect
                        isMulti
                        cacheOptions
                        isSearchable
                        isClearable
                        isCreatable
                        debounceTimeout={300}
                        isLoading={tagListLoad}
                        name="tags"
                        id="tags"
                        placeholder="Select a tag"
                        menuPosition="fixed"
                        strategy="fixed"
                        className="pb-take-popover-select font-soehne-mono"
                        value={tagList?.filter((tag) =>
                          tags?.includes(tag.value),
                        )}
                        onChange={(opt) => {
                          if (opt?.length > 0) {
                            setTags(opt.map((tag) => tag.value));
                          } else {
                            setTags([]);
                          }
                        }}
                        loadOptions={getTagsResponse}
                        additional={{ page: 1 }}
                        onCreateOption={(option) => createTagResponse(option)}
                      />
                    </div>

                    <div className="flex justify-end space-x-4">
                      <Button
                        label="Cancel"
                        className="!w-fit"
                        onClick={() => setTagPillPopover(false)}
                        style="link"
                      />

                      <Button
                        label="Save"
                        loading={btnLoader}
                        className="!w-fit"
                        type="submit"
                        onClick={() => updateAttachmentResponse()}
                      />
                    </div>
                  </div>
                }
                trigger="click"
                open={tagPillPopover}
                placement="bottom"
                overlayClassName="w-[350px]"
              >
                <span
                  className="px-4 py-1.5 text-sm uppercase rounded-full bg-white shadow-md cursor-pointer"
                  onClick={() => {
                    setLinkPillPopover(false);
                    setAltPillPopover(false);
                    setTagPillPopover(true);
                  }}
                >
                  Tag
                </span>
              </Popover>
            )}
          </div>
        </div>
      )}

      <div className="absolute right-5 top-5">
        <div className="flex items-center px-2 py-1 bg-white rounded-[32px] shadow-lg">
          <Popover
            content={
              <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                <div className="flex flex-col space-y-2 w-full">
                  <span className="text-[14px]">Link</span>
                  <input
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                    className="pb-take-popover-input"
                    placeholder="Enter a link"
                  />
                  {linkError?.length > 0 && (
                    <p className="text-sm text-[#CB4446]">{linkError}</p>
                  )}
                </div>

                {link && (
                  <div className="flex justify-between gap-x-3">
                    <label className="text-[14px] m-0">Show link button</label>
                    <div className="w-[45px]">
                      <Switch
                        checked={showLinkButton}
                        onChange={() => {
                          setInitial(false);
                          setShowLinkButton(!showLinkButton);
                        }}
                      />
                    </div>
                  </div>
                )}

                {link && showLinkButton && (
                  <div className="flex flex-col space-y-2 w-full">
                    <span className="text-[14px]">Button Text</span>
                    <input
                      value={buttonText}
                      onChange={(e) => setButtonText(e.target.value)}
                      className="pb-take-popover-input"
                      placeholder="Add display text"
                    />
                  </div>
                )}

                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    className="!w-fit"
                    onClick={() => setLinkPopover(false)}
                    style="link"
                  />

                  <Button
                    label="Insert"
                    loading={btnLoader}
                    className="!w-fit"
                    type="submit"
                    onClick={() => {
                      if (link?.length > 0 && !isValidUrl(link)) {
                        setLinkError("Link is not valid.");
                      } else {
                        setLinkError("");
                        updateAttachmentResponse();
                      }
                    }}
                  />
                </div>
              </div>
            }
            trigger="click"
            open={linkPopover}
            placement="bottom"
            overlayClassName="w-[350px]"
          >
            <button
              className="cursor-pointer px-2 py-1 pb-menu-border"
              onClick={() => {
                setLinkPopover(true);
                setAltPopover(false);
                setTagPopover(false);
                setDeletePopover(false);
              }}
            >
              <LinkIcon />
            </button>
          </Popover>

          <Popover
            content={
              <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                <div className="flex flex-col space-y-2 w-full">
                  <span className="text-[14px] h-full">Alt Text</span>
                  <input
                    value={altText}
                    onChange={(e) => setAltText(e.target.value)}
                    className="pb-take-popover-input"
                    placeholder="Enter a alt text"
                  />
                  <p className="m-0 text-[#7B7E82] text-sm">
                    Add a short written description of your image. This allows
                    your images to be accessible to those who are using a screen
                    reader, are vision impaired or blind.
                  </p>
                </div>

                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    className="!w-fit"
                    onClick={() => setAltPopover(false)}
                    style="link"
                  />

                  <Button
                    label="Save"
                    loading={btnLoader}
                    className="!w-fit"
                    type="submit"
                    onClick={() => updateAttachmentResponse()}
                  />
                </div>
              </div>
            }
            trigger="click"
            open={altPopover}
            placement="bottom"
            overlayClassName="w-[350px]"
          >
            <button
              className="cursor-pointer px-2 py-1 pb-menu-border"
              onClick={() => {
                setLinkPopover(false);
                setAltPopover(true);
                setTagPopover(false);
                setDeletePopover(false);
              }}
            >
              <ALTIcon />
            </button>
          </Popover>

          <Popover
            content={
              <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                <div className="flex flex-col space-y-2 w-full">
                  <span className="text-[14px]">Tags</span>
                  <AsyncPaginateSelect
                    isMulti
                    cacheOptions
                    isSearchable
                    isClearable
                    isCreatable
                    debounceTimeout={300}
                    isLoading={tagListLoad}
                    name="tags"
                    id="tags"
                    placeholder="Select a tag"
                    menuPosition="fixed"
                    strategy="fixed"
                    className="pb-take-popover-select font-soehne-mono"
                    value={tagList?.filter((tag) => tags?.includes(tag.value))}
                    onChange={(opt) => {
                      if (opt?.length > 0) {
                        setTags(opt.map((tag) => tag.value));
                      } else {
                        setTags([]);
                      }
                    }}
                    loadOptions={getTagsResponse}
                    defaultOptions={tagList}
                    additional={{ page: 1 }}
                    onCreateOption={(option) => createTagResponse(option)}
                  />
                </div>

                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    className="!w-fit"
                    onClick={() => setTagPopover(false)}
                    style="link"
                  />

                  <Button
                    label="Save"
                    loading={btnLoader}
                    className="!w-fit"
                    type="submit"
                    onClick={() => updateAttachmentResponse()}
                  />
                </div>
              </div>
            }
            trigger="click"
            open={tagPopover}
            placement="bottom"
            overlayClassName="w-[350px]"
          >
            <button
              className="cursor-pointer px-2 py-1 pb-menu-border"
              onClick={() => {
                setLinkPopover(false);
                setAltPopover(false);
                setTagPopover(true);
                setDeletePopover(false);
              }}
            >
              <TagIcon />
            </button>
          </Popover>

          <Popover
            content={
              <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                <div className="flex flex-col space-y-2 w-full">
                  <span className="text-[14px]">Delete Image</span>
                  <p className="text-sm m-0">
                    Are you sure you want to delete the image?
                  </p>
                </div>

                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    className="!w-fit"
                    onClick={() => setDeletePopover(false)}
                    style="link"
                  />

                  <Button
                    label="Yes"
                    loading={btnLoader}
                    className="!w-fit"
                    type="submit"
                    onClick={() => destroyAttachmentResponse()}
                  />
                </div>
              </div>
            }
            trigger="click"
            open={deletePopover}
            placement="bottom"
            overlayClassName="w-[350px]"
          >
            <button
              className="cursor-pointer px-2 py-1 pb-menu-border"
              onClick={() => {
                setLinkPopover(false);
                setAltPopover(false);
                setTagPopover(false);
                setDeletePopover(true);
              }}
            >
              <TrashIcon />
            </button>
          </Popover>

          <Menu>
            <MenuButton
              className="cursor-pointer px-2 py-1"
              onClick={() => {
                setLinkPopover(false);
                setAltPopover(false);
                setTagPopover(false);
                setDeletePopover(false);
              }}
            >
              <MenuHorizontal />
            </MenuButton>

            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom end"
                className="origin-top-left rounded-lg border border-solid border-[#E5E0D9] bg-white p-2 shadow-lg focus:outline-none z-50"
              >
                <MenuItem
                  onClick={() => {
                    setInitial(false);
                    setExcludeFromArchive(!excludeFromArchive);
                  }}
                >
                  <button className="flex items-center w-full p-2 text-[16px] font-soehne-mono">
                    {excludeFromArchive ? (
                      <>
                        <EyeIcon />
                        <span className="ml-2">Include to Visual Archive</span>
                      </>
                    ) : (
                      <>
                        <CloseEyeIcon />
                        <span className="ml-2">
                          Exclude from Visual Archive
                        </span>
                      </>
                    )}
                  </button>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setInitial(false);
                    setHeroImage(!heroImage);
                    updateHeroImageAttachmentResponse(!heroImage);
                  }}
                >
                  <button className="flex items-center w-full p-2 text-[16px] font-soehne-mono">
                    {heroImage ? (
                      <>
                        <HeroImageIcon />
                        <span className="ml-2">Remove from Hero Image</span>
                      </>
                    ) : (
                      <>
                        <HeroImageIcon />
                        <span className="ml-2">Set as hero image</span>
                      </>
                    )}
                  </button>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (!attachment.in_top_position) {
                      reorderAttachmentResponse(attachment.id, "move_up");
                    }
                  }}
                >
                  <button
                    className={`flex items-center w-full p-2 text-[16px] font-soehne-mono ${attachment.in_top_position ? "cursor-not-allowed" : "cursor-pointer"}`}
                  >
                    {attachment.in_top_position ? (
                      <>
                        <DisableMoveUpIcon />
                        <span className="text-[#B2B2B2] ml-2">
                          Move image up
                        </span>
                      </>
                    ) : (
                      <>
                        <MoveUpIcon />
                        <span className="ml-2">Move image up</span>
                      </>
                    )}
                  </button>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (!attachment.in_bottom_position) {
                      reorderAttachmentResponse(attachment.id, "move_down");
                    }
                  }}
                >
                  <button
                    className={`flex items-center w-full p-2 text-[16px] font-soehne-mono ${attachment.in_bottom_position ? "cursor-not-allowed" : "cursor-pointer"}`}
                  >
                    {attachment.in_bottom_position ? (
                      <>
                        <DisableMoveDownIcon />
                        <span className="text-[#B2B2B2] ml-2">
                          Move image down
                        </span>
                      </>
                    ) : (
                      <>
                        <MoveDownIcon />
                        <span className="ml-2">Move image down</span>
                      </>
                    )}
                  </button>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>

      {attachment.imageLoader ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 transition-all opacity-100 backdrop-blur-sm">
          <Loading size={45} className="animate-spin-slow text-white" />
        </div>
      ) : (
        <motion.div
          animate={{
            opacity: 1,
            height: hasContent() ? "auto" : 0,
            overflow: hasContent() ? "visible" : "hidden",
          }}
          exit={{ opacity: 0, height: 0 }}
          initial={{ opacity: 0, height: 0, overflow: "hidden" }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col space-y-2 w-full mt-8">
            <RichTextEditorComponent
              className="pb-take-title-input"
              id={`image-title-editior-${attachment.id}`}
              inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
              toolbarSettings={{
                items: ["Alignments"],
              }}
              onBlur={() => updateAttachmentResponse()}
              change={(e) => {
                setTitleText(e.value);
                setClicked(true);
              }}
              value={titleText}
              placeholder="Give your image a title"
            >
              <Inject
                services={[Image, Link, QuickToolbar, HtmlEditor, Toolbar]}
              />
            </RichTextEditorComponent>

            <RichTextEditorComponent
              id={`image-caption-editior-${attachment.id}`}
              inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
              toolbarSettings={RICH_TEXT_EDITOR_TOOLBAR}
              onBlur={() => updateAttachmentResponse()}
              change={(e) => setCaptionText(e.value)}
              value={captionText}
              placeholder="Write something about your image"
            >
              <Inject
                services={[Image, Link, QuickToolbar, HtmlEditor, Toolbar]}
              />
            </RichTextEditorComponent>

            {attachmentData.link && attachmentData.show_link_button && (
              <div className="flex justify-center !mt-8">
                <LinkButton
                  to={attachmentData.link}
                  label={attachmentData.button_text || "Link"}
                  className="!w-fit !text-center"
                />
              </div>
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default TakeImage;
