import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@/components/commons/SettingsFormUI";

const FormCardBack = ({ user, imageHeight }) => {
  return (
    <div className="flex flex-col items-center w-full p-6 gap-y-6 sm:px-12 sm:pt-12">
      <div
        className="overflow-hidden rounded-full"
        style={{ height: imageHeight, width: imageHeight }}
      >
        <img
          src={user.logo_url}
          alt="artist icon"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="text-[28px] leading-[33.41px] text-center font-semibold font-sf-pro sm:text-[32px] sm:leading-[38.19px]">
        {user.display_name}
      </div>

      <div className="flex gap-x-3">
        <Button type="submit" label="Subscribed!" loginBtn />
      </div>

      <div className="px-[48px] py-[12px]">
        <Link
          to={`/archive/${user.url_slug}`}
          className="text-[16px] leading-[24px] font-medium"
          onClick={() => window.scrollTo(0, 0)}
        >
          View archives
        </Link>
      </div>
    </div>
  );
};

export default FormCardBack;
